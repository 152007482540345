








































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormInput,
  BFormGroup,
  BButton,
  BInputGroup,
  BInputGroupAppend,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { AvButton } from "@/components";
import { AvSkeletonInput } from "@/components/av-skeleton";
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import { Component, Vue, Watch } from "vue-property-decorator";
import { BFormRadio } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

// Services
import { useDealerships, useGroup, useState } from "@/services";

// Interfaces
import {
  IDealerships,
  IFieldControl,
  IResponseCreateDealership,
  IResponseGetDealership,
  IUpdateDealershipBody,
} from "@core/services/interfaces/business/dealerships/IDealershipsService";
import {
  ICityStateApi,
  IResponseGetCitiesByStateId,
  IResponseListState,
  IStateApi,
} from "@core/services/interfaces/covarege-area/state/IStateService";
import { IOption } from "@core/services/interfaces/IUtil";
import {
  IResponseListGroup,
  TDataGroup,
} from "@core/services/interfaces/business/group/IGroupService";

// eslint-disable-next-line no-underscore-dangle
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
/* eslint-disable global-require */

interface IOptionCity extends IOption {
  area: string;
  region: string;
  ibgeCode: string;
}

@Component({
  name: "DealershipsEdit",
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BFormInvalidFeedback,
    vSelect,
    LMap,
    LTileLayer,
    LMarker,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BFormRadio,
    AvButton,
    AvSkeletonInput,
  },
  directives: {
    Ripple,
  },
})
export default class DealershipsEdit extends Vue {
  // Data
  groupTitle = this.$store.state["appConfig"].params.GRUPO_EMPRESA_TITLE;
  dealershipLocal: IDealerships = {} as IDealerships;
  faturarCodigoBir = false;
  searchAddress = "";
  url = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
  zoom = 8;
  center = [-15.7931351608, -47.8585332325];
  coordinates: [number, number] = [-15.7931351608, -47.8585332325];
  dealershipId: string = "";
  cityId: IOptionCity | null = null;
  editing: boolean = false;
  stateOptions: IOption[] = [];
  groupOptions: IOption[] = [];
  municipioOptions: IOption[] = [];
  geocodeEndpoint: string = "https://maps.googleapis.com/maps/api/geocode/json";
  apiKey: string = "AIzaSyBk_fiUOC8dyqXCxeQfYZfDYAcBNKQkMgc";
  confirmationOptions: IOption[] = [
    { label: "Sim", value: true },
    { label: "Não", value: false },
  ];
  financeOptions = [
    { label: "Adimplente", value: false },
    { label: "Inadimplente", value: true },
  ];

  // Loading Control
  loading: boolean = false;
  saving: boolean = false;
  loadingDealership = false;
  loadingState = false;
  loadingCities = false;
  loadingCoordenates = false;
  loadingGroup = false;
  validatingCnpj = false;
  validCnpj = false;

  fieldControl = {} as IFieldControl;

  formValidation = formValidation();

  // Computeds
  get estadoValue(): string {
    return this.dealershipLocal.Estado;
  }

  get cidadeValue(): string {
    return this.dealershipLocal.Municipio;
  }

  get getAtivoMd(): number {
    if (
      !this.fieldControl.DataCancelamento.required ||
      !this.fieldControl.DataCancelamento.optional
    )
      return 12;

    return this.dealershipLocal.Status === false ? 4 : 6;
  }

  get isValidCnpj(): boolean | null {
    if (typeof this.dealershipLocal.CNPJ == "undefined") {
      return null;
    }

    return this.dealershipLocal.CNPJ != "" ? this.validCnpj : null;
  }

  get showLocationInfo(): boolean {
    if (this.fieldControl.CEP.required || this.fieldControl.CEP.optional)
      return true;
    if (
      this.fieldControl.Logradouro.required ||
      this.fieldControl.Logradouro.optional
    )
      return true;
    if (this.fieldControl.Numero.required || this.fieldControl.Numero.optional)
      return true;
    if (this.fieldControl.Bairro.required || this.fieldControl.Bairro.optional)
      return true;
    if (
      this.fieldControl.Complemento.required ||
      this.fieldControl.Complemento.optional
    )
      return true;
    if (
      this.fieldControl.EstadoId.required ||
      this.fieldControl.EstadoId.optional
    )
      return true;
    if (
      this.fieldControl.MunicipioId.required ||
      this.fieldControl.MunicipioId.optional
    )
      return true;
    if (
      this.fieldControl.Coordenadas.required ||
      this.fieldControl.Coordenadas.optional
    )
      return true;

    return false;
  }

  // LifeCycle
  created() {
    if (this.$route.params.id) {
      this.dealershipId = this.$route.params.id;
    }

    if (this.dealershipId != "") {
      this.editing = true;
      this.fetchGetDealershipById();
    }

    this.getStates();
    this.getGroups();

    this.fieldControl = useDealerships.getFieldControl();
  }

  @Watch("dealershipLocal.EstadoId")
  fetchGetCitiesByStateId() {
    this.loadingCities = true;

    useState
      .requestGetCityByStateId(this.dealershipLocal.EstadoId)
      .then((response: IResponseGetCitiesByStateId) => {
        this.municipioOptions = response.data.Data.data.map(
          (city: ICityStateApi) => {
            if (city.Id == this.dealershipLocal.MunicipioId) {
              this.dealershipLocal.CidadeCodigoIbge = city.CodigoIbge;
              this.cityId = {
                label: city.Nome,
                value: city.Id,
                area: city.AreaInfluenciaNome,
                region: city.RegiaoOperacionalNome,
                ibgeCode: city.CodigoIbge,
              };
            }

            return {
              label: city.Nome,
              value: city.Id,
              area: city.AreaInfluenciaNome,
              region: city.RegiaoOperacionalNome,
              ibgeCode: city.CodigoIbge,
            };
          }
        );
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao buscar os municípios!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loadingCities = false;
      });
  }

  @Watch("coordinates")
  watchSelectedLatLng() {
    this.dealershipLocal.Latitude = this.coordinates[0];
    this.dealershipLocal.Longitude = this.coordinates[1];

    const map = this.$refs.mapRef as LMap;
    if (map && map.mapObject) {
      map.mapObject.setView(this.coordinates);
    }
  }

  // Methods
  cnpjExist(cpnj: string) {
    const regexApenasNumeros = /[^\d]/g;
    const cpnjSanatize = (cpnj || "").replace(regexApenasNumeros, "");

    if (cpnjSanatize.length == 14) {
      this.validatingCnpj = true;

      useDealerships
        .requestValidCnpj(cpnj, this.dealershipId != "" ? true : false)
        .then((valid) => {
          if (typeof valid == "boolean") this.validCnpj = valid;
          else this.validCnpj = false;

          if (!valid) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Esse CNPJ já ta em uso",
                icon: "AlertTriangleIcon",
                variant: "warning",
              },
            });
          }
        })
        .catch(() => {
          this.validCnpj = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Não foi possível validar esse CNPJ!",
              icon: "AlertTriangleIcon",
              variant: "warning",
            },
          });
        })
        .finally(() => {
          this.validatingCnpj = false;
        });
    } else {
      this.validCnpj = false;
    }
  }

  checkStateCnpj(validationContext: any): boolean | null {
    if (this.validatingCnpj) {
      return null;
    }

    const formValidation =
      this.formValidation.getValidationState(validationContext);

    if (formValidation === null && this.isValidCnpj === null) {
      return null;
    } else if (this.isValidCnpj === null) {
      return formValidation;
    } else {
      return this.isValidCnpj;
    }
  }

  fetchGetDealershipById() {
    this.loadingDealership = true;
    useDealerships
      .requestGet(this.dealershipId)
      .then((response: IResponseGetDealership) => {
        this.dealershipLocal = response.data.Data;
        this.cnpjExist(this.dealershipLocal.CNPJ);

        if (this.dealershipLocal.CodigoFaturamento) {
          this.faturarCodigoBir = true;
        }

        if (this.dealershipLocal.Longitude && this.dealershipLocal.Latitude) {
          this.coordinates = [
            this.dealershipLocal.Latitude,
            this.dealershipLocal.Longitude,
          ];
        }

        if (!this.dealershipLocal.Complemento) {
          this.dealershipLocal.Complemento = "";
        }

        if (this.dealershipLocal.DataInauguracao) {
          this.dealershipLocal.DataInauguracao =
            this.dealershipLocal.DataInauguracao.split("T")[0];
        }

        if (this.dealershipLocal.DataNomeacao) {
          this.dealershipLocal.DataNomeacao =
            this.dealershipLocal.DataNomeacao.split("T")[0];
        }

        if (this.dealershipLocal.DataCancelamento) {
          this.dealershipLocal.DataCancelamento =
            this.dealershipLocal.DataCancelamento.split("T")[0];
        }
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao buscar a concessionária!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loadingDealership = false;
      });
  }

  getStates() {
    this.loadingState = true;

    useState
      .requestList({
        paginar: false,
        municipios: [],
      })
      .then((response: IResponseListState) => {
        this.stateOptions = response.data.Data.data.map((state: IStateApi) => {
          return { label: state.Nome, value: state.Id };
        });
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao buscar os estados!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loadingState = false;
      });
  }

  limitDigits(event: KeyboardEvent) {
    const keyCode = event.keyCode || event.which;
    const inputValue = String.fromCharCode(keyCode);
    const newValue = (this.dealershipLocal.Codigo || "") + inputValue;

    if (newValue.length > 9) {
      event.preventDefault();
      return;
    }
  }

  getGroups() {
    this.loadingGroup = true;

    useGroup
      .requestListGroup({
        draw: 1,
        length: 10000,
        paginate: false,
      })
      .then((response: IResponseListGroup) => {
        this.groupOptions = response.data.data.map((group: TDataGroup) => {
          return { label: group.Nome, value: group.Id };
        });
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao buscar os estados!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loadingGroup = false;
      });
  }

  reduceOptionValue = (option: { label: string; value: any }): any => {
    return option.value;
  };

  changeCityId(option: IOptionCity): void {
    this.dealershipLocal.MunicipioId = option.value;
    this.dealershipLocal.AreaInfluenciaNome = option.area;
    this.dealershipLocal.RegiaoOperacionalNome = option.region;
    this.dealershipLocal.CidadeCodigoIbge = option.ibgeCode;
  }

  updateCoordinates(e: any) {
    this.coordinates = [e.target.getLatLng().lat, e.target.getLatLng().lng];
  }

  searchCoordinates() {
    this.loadingCoordenates = true;
    const url = `${this.geocodeEndpoint}?address=${encodeURIComponent(
      this.searchAddress
    )}&key=${this.apiKey}`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (data.results && data.results.length > 0) {
          const { lat, lng } = data.results[0].geometry.location;
          this.coordinates = [lat, lng];
        }
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao buscar coordenadas!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loadingCoordenates = false;
      });
  }

  onSubmit() {
    this.saving = true;

    this.$refs.refFormObserver
      // @ts-ignore
      .validate()
      .then((success: boolean) => {
        if (success && this.isValidCnpj) {
          let business: IUpdateDealershipBody = {
            id: this.dealershipId != "" ? this.dealershipId : undefined,
            municipioId: this.dealershipLocal.MunicipioId || "",
            grupoEmpresaId: this.dealershipLocal.GrupoEmpresaId,
            cnpj: this.dealershipLocal.CNPJ,
            razaoSocial: this.dealershipLocal.RazaoSocial,
            nomeFantasia: this.dealershipLocal.NomeFantasia,
            montadora: this.dealershipLocal.Montadora,
            ativo: this.dealershipLocal.Status,
            associada: this.dealershipLocal.Associada,
            codigo: this.dealershipLocal.Codigo,
            cadastrada: this.dealershipLocal.Cadastrada,
            telefone: this.dealershipLocal.Telefone,
            mail: this.dealershipLocal.Mail,
            emailFaturamento: this.dealershipLocal.EmailFaturamento,
            site: this.dealershipLocal.Site,
            inscricaoEstadual: this.dealershipLocal.InscricaoEstadual,
            inscricaoMunicipal: this.dealershipLocal.InscricaoMunicipal,
            dataInauguracao: this.dealershipLocal.DataInauguracao,
            dataNomeacao: this.dealershipLocal.DataNomeacao,
            dataCancelamento: this.dealershipLocal.DataCancelamento,
            pendenciaFinanceira: this.dealershipLocal.PendenciaFinanceira,
            codigoFaturamento: this.dealershipLocal.CodigoFaturamento,
            matriz: this.dealershipLocal.Matriz,
            observacoes: this.dealershipLocal.Observacoes,
            endereco: null,
          };

          if (
            this.fieldControl.Endereco.required ||
            this.fieldControl.Endereco.optional
          ) {
            business.endereco = {
              enderecoId: this.dealershipLocal.EnderecoId,
              cep: this.dealershipLocal.CEP,
              logradouro: this.dealershipLocal.Logradouro,
              numero: this.dealershipLocal.Numero,
              complemento: this.dealershipLocal.Complemento || "",
              bairro: this.dealershipLocal.Bairro,
              cidadeCodigoIbge: this.dealershipLocal.CidadeCodigoIbge || "",
              latitude: this.dealershipLocal.Latitude,
              longitude: this.dealershipLocal.Longitude,
            };
          }

          if (this.dealershipId != "") {
            useDealerships
              .requestUpdate(business)
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Empresa atualizada com sucesso!",
                    icon: "CheckIcon",
                    variant: "success",
                  },
                });

                this.$router.push({
                  name: "admin-panel-dealerships-list",
                });
              })
              .catch((error: any) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Erro ao atualizar empresa!",
                    text: error.response.data.Errors.reduce(
                      (accumulator: string, currentValue: string) => {
                        const str = accumulator != "" ? " | " : "";
                        return str + " " + currentValue;
                      },
                      ""
                    ),
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                  },
                });
              })
              .finally(() => {
                this.saving = false;
              });
          } else {
            useDealerships
              .requestCreate(business)
              .then((response: IResponseCreateDealership) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Empresa criada com sucesso!",
                    icon: "CheckIcon",
                    variant: "success",
                  },
                });

                this.$router.push({
                  name: "admin-panel-dealerships-list",
                });
              })
              .catch((error: any) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Erro ao criar empresa!",
                    text: error.response.data.Errors.reduce(
                        (accumulator: string, currentValue: string) => {
                          const str = accumulator != "" ? " | " : "";
                          return str + " " + currentValue;
                        },
                        ""
                    ),
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                  },
                });
              })
              .finally(() => {
                this.saving = false;
              });
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Preencha todos os campos obrigatórios!",
              icon: "AlertTriangleIcon",
              variant: "warning",
            },
          });

          if (document.querySelectorAll(".is-invalid").length) {
            const y =
              document
                .querySelectorAll(".is-invalid")[0]
                .getBoundingClientRect().top +
              window.scrollY -
              125;
            window.scroll({
              top: y,
              behavior: "smooth",
            });
          }

          this.saving = false;
        }
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title:
              "Ocorreu um erro inesperado! Acione a administração do sistema",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });

        this.saving = false;
      });
  }
}
